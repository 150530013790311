import React from "react";
import Separator from "./separator";
import dataSeparator from "../fixtures/separator.json";
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";
const Services = () => {
  const AnimatedElement = ({ children }) => {
    const ref = React.useRef(null);
    const intersection = useIntersection(ref, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    const springProps = useSpring({
      opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
      transform:
        intersection && intersection.intersectionRatio >= 0.1
          ? "translateY(0)"
          : "translateY(40px)",
      config: { tension: 40, friction: 20 },
    });

    return (
      <animated.div ref={ref} style={springProps}>
        {children}
      </animated.div>
    );
  };
  return (
    <>
      <section id="services">
        <div className="title">
          <AnimatedElement>
            <h3>NOS SERVICES</h3>
          </AnimatedElement>
          <h4>Des prestations adaptées à vos besoins</h4>
          <hr className="line-barre" />
        </div>
        <div className="services-content">
          <ul className="block-left">
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>GESTION DE PROJETS WEB</h5>
                  <p>Site vitrine, évènementiel, blog, e-commerce.</p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 2.5c-3.81 0-6.5 2.743-6.5 6.119c0 1.536.632 2.572 1.425 3.56c.172.215.347.422.527.635l.096.112c.21.25.427.508.63.774c.404.531.783 1.128.995 1.834a.75.75 0 0 1-1.436.432c-.138-.46-.397-.89-.753-1.357a18.111 18.111 0 0 0-.582-.714l-.092-.11c-.18-.212-.37-.436-.555-.667C4.87 12.016 4 10.651 4 8.618C4 4.363 7.415 1 12 1s8 3.362 8 7.619c0 2.032-.87 3.397-1.755 4.5c-.185.23-.375.454-.555.667l-.092.109c-.21.248-.405.481-.582.714c-.356.467-.615.898-.753 1.357a.751.751 0 0 1-1.437-.432c.213-.706.592-1.303.997-1.834c.202-.266.419-.524.63-.774l.095-.112c.18-.213.355-.42.527-.634c.793-.99 1.425-2.025 1.425-3.561C18.5 5.243 15.81 2.5 12 2.5M8.75 18h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1 0-1.5m.75 3.75a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75"
                    />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>INTÉGRATION WEB</h5>
                  <p>
                    Des intégrations HTML, CSS, SASS & JavaScript respectueuses
                    des standards du Web.
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 4a2 2 0 0 0-2 2v3a2 3 0 0 1-2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0-2 3v3a2 2 0 0 1-2 2"
                    />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>DÉVELOPPEMENTS SPÉCIFIQUES</h5>
                  <p>
                    Des outils adaptés à votre coeur de métier, applications &
                    solutions personnalisées.
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9M3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5L3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708z" />
                      <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z" />
                    </g>
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>RÉFÉRENCEMENT NATUREL</h5>
                  <p>
                    Affichage sémantique des informations, des pages propres
                    pour un référencement optimal.
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 48 48"
                  >
                    <g fill="none" stroke="currentColor" strokeWidth="4">
                      <rect width="40" height="32" x="4" y="8" rx="1.633" />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16 18.948c-2-2.948-5.502-1.01-5.251 2.02C11 24 15 24 15.249 27.032C15.5 30.062 12 32 10 29.051M26 18h-4v13h4m-4-6h4"
                      />
                      <rect
                        width="6"
                        height="13"
                        x="32"
                        y="18"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        rx="3"
                      />
                    </g>
                  </svg>
                </div>
              </li>
            </AnimatedElement>
          </ul>
          {/* <div className="services-block-first-img">
                        <img src="./assets/images/services_img.jpg" alt="work" />
                    </div> */}
          <ul className="block-right">
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>CONCEPTION GRAPHIQUE & WEBDESIGN</h5>
                  <p>
                    Logos, Maquette Web, annonces publicitaires, cartes de
                    visite,newsletters...
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="currentColor"
                      d="M190.15 96A44 44 0 0 0 160 20H88a44 44 0 0 0-30.15 76a43.9 43.9 0 0 0 1.23 65.12A48 48 0 1 0 140 196v-28.83A44 44 0 0 0 190.15 96M180 64a20 20 0 0 1-20 20h-20V44h20a20 20 0 0 1 20 20M68 64a20 20 0 0 1 20-20h28v40H88a20 20 0 0 1-20-20m20 84a20 20 0 0 1 0-40h28v40zm28 48a24 24 0 1 1-24-24h24Zm44-48a20 20 0 1 1 20-20a20 20 0 0 1-20 20"
                    />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>DYNAMISME DES PAGES</h5>
                  <p>
                    Des animations de contenu non intrusives pour embellir votre
                    projet.
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 10.11c1.03 0 1.87.84 1.87 1.89c0 1-.84 1.85-1.87 1.85c-1.03 0-1.87-.85-1.87-1.85c0-1.05.84-1.89 1.87-1.89M7.37 20c.63.38 2.01-.2 3.6-1.7c-.52-.59-1.03-1.23-1.51-1.9a22.7 22.7 0 0 1-2.4-.36c-.51 2.14-.32 3.61.31 3.96m.71-5.74l-.29-.51c-.11.29-.22.58-.29.86c.27.06.57.11.88.16zm6.54-.76l.81-1.5l-.81-1.5c-.3-.53-.62-1-.91-1.47C13.17 9 12.6 9 12 9c-.6 0-1.17 0-1.71.03c-.29.47-.61.94-.91 1.47L8.57 12l.81 1.5c.3.53.62 1 .91 1.47c.54.03 1.11.03 1.71.03c.6 0 1.17 0 1.71-.03c.29-.47.61-.94.91-1.47M12 6.78c-.19.22-.39.45-.59.72h1.18c-.2-.27-.4-.5-.59-.72m0 10.44c.19-.22.39-.45.59-.72h-1.18c.2.27.4.5.59.72M16.62 4c-.62-.38-2 .2-3.59 1.7c.52.59 1.03 1.23 1.51 1.9c.82.08 1.63.2 2.4.36c.51-2.14.32-3.61-.32-3.96m-.7 5.74l.29.51c.11-.29.22-.58.29-.86c-.27-.06-.57-.11-.88-.16zm1.45-7.05c1.47.84 1.63 3.05 1.01 5.63c2.54.75 4.37 1.99 4.37 3.68c0 1.69-1.83 2.93-4.37 3.68c.62 2.58.46 4.79-1.01 5.63c-1.46.84-3.45-.12-5.37-1.95c-1.92 1.83-3.91 2.79-5.38 1.95c-1.46-.84-1.62-3.05-1-5.63c-2.54-.75-4.37-1.99-4.37-3.68c0-1.69 1.83-2.93 4.37-3.68c-.62-2.58-.46-4.79 1-5.63c1.47-.84 3.46.12 5.38 1.95c1.92-1.83 3.91-2.79 5.37-1.95M17.08 12c.34.75.64 1.5.89 2.26c2.1-.63 3.28-1.53 3.28-2.26c0-.73-1.18-1.63-3.28-2.26c-.25.76-.55 1.51-.89 2.26M6.92 12c-.34-.75-.64-1.5-.89-2.26c-2.1.63-3.28 1.53-3.28 2.26c0 .73 1.18 1.63 3.28 2.26c.25-.76.55-1.51.89-2.26m9 2.26l-.3.51c.31-.05.61-.1.88-.16c-.07-.28-.18-.57-.29-.86zm-2.89 4.04c1.59 1.5 2.97 2.08 3.59 1.7c.64-.35.83-1.82.32-3.96c-.77.16-1.58.28-2.4.36c-.48.67-.99 1.31-1.51 1.9M8.08 9.74l.3-.51c-.31.05-.61.1-.88.16c.07.28.18.57.29.86zm2.89-4.04C9.38 4.2 8 3.62 7.37 4c-.63.35-.82 1.82-.31 3.96a22.7 22.7 0 0 1 2.4-.36c.48-.67.99-1.31 1.51-1.9"
                    />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>INTERFACE D'ADMINISTRATION</h5>
                  <p>
                    Outils spécifiques au bon fonctionnement de votre
                    entreprise.
                  </p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M14.68 14.81a6.76 6.76 0 1 1 6.76-6.75a6.77 6.77 0 0 1-6.76 6.75m0-11.51a4.76 4.76 0 1 0 4.76 4.76a4.76 4.76 0 0 0-4.76-4.76"
                      className="clr-i-outline clr-i-outline-path-1"
                    />
                    <path
                      fill="currentColor"
                      d="M16.42 31.68A2.14 2.14 0 0 1 15.8 30H4v-5.78a14.81 14.81 0 0 1 11.09-4.68h.72a2.2 2.2 0 0 1 .62-1.85l.12-.11c-.47 0-1-.06-1.46-.06A16.47 16.47 0 0 0 2.2 23.26a1 1 0 0 0-.2.6V30a2 2 0 0 0 2 2h12.7Z"
                      className="clr-i-outline clr-i-outline-path-2"
                    />
                    <path
                      fill="currentColor"
                      d="M26.87 16.29a.37.37 0 0 1 .15 0a.42.42 0 0 0-.15 0"
                      className="clr-i-outline clr-i-outline-path-3"
                    />
                    <path
                      fill="currentColor"
                      d="m33.68 23.32l-2-.61a7.21 7.21 0 0 0-.58-1.41l1-1.86A.38.38 0 0 0 32 19l-1.45-1.45a.36.36 0 0 0-.44-.07l-1.84 1a7.15 7.15 0 0 0-1.43-.61l-.61-2a.36.36 0 0 0-.36-.24h-2.05a.36.36 0 0 0-.35.26l-.61 2a7 7 0 0 0-1.44.6l-1.82-1a.35.35 0 0 0-.43.07L17.69 19a.38.38 0 0 0-.06.44l1 1.82a6.77 6.77 0 0 0-.63 1.43l-2 .6a.36.36 0 0 0-.26.35v2.05A.35.35 0 0 0 16 26l2 .61a7 7 0 0 0 .6 1.41l-1 1.91a.36.36 0 0 0 .06.43l1.45 1.45a.38.38 0 0 0 .44.07l1.87-1a7.09 7.09 0 0 0 1.4.57l.6 2a.38.38 0 0 0 .35.26h2.05a.37.37 0 0 0 .35-.26l.61-2.05a6.92 6.92 0 0 0 1.38-.57l1.89 1a.36.36 0 0 0 .43-.07L32 30.4a.35.35 0 0 0 0-.4l-1-1.88a7 7 0 0 0 .58-1.39l2-.61a.36.36 0 0 0 .26-.35v-2.1a.36.36 0 0 0-.16-.35M24.85 28a3.34 3.34 0 1 1 3.33-3.33A3.34 3.34 0 0 1 24.85 28"
                      className="clr-i-outline clr-i-outline-path-4"
                    />
                    <path fill="none" d="M0 0h36v36H0z" />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
            <AnimatedElement>
              <li>
                <article className="block-texte">
                  <h5>RESPONSIVE DESIGN</h5>
                  <p>Compatible tous supports, tablette & mobile.</p>
                </article>
                <div className="round">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M4 6v10h5v-4a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v4h2V6zM0 20v-2h4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h4v2h-6a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2zm11.5 0a.5.5 0 0 0-.5.5a.5.5 0 0 0 .5.5a.5.5 0 0 0 .5-.5a.5.5 0 0 0-.5-.5m4 0a.5.5 0 0 0-.5.5a.5.5 0 0 0 .5.5a.5.5 0 0 0 .5-.5a.5.5 0 0 0-.5-.5M13 20v1h1v-1zm-2-8v7h5v-7z"
                    />
                  </svg>
                </div>
              </li>
            </AnimatedElement>
          </ul>
        </div>
      </section>
      <Separator
        imgSrc={"./assets/images/services_background.webp"}
        data={dataSeparator.service}
      />
    </>
  );
};

export default Services;
