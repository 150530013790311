import React, { useState, useEffect } from "react";
import cgv from "../fixtures/cgv.json";

const ModalCgv = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLinkClick = () => {
    document.body.classList.remove('modal-open');
    handleModalToggle(); // Fermer la modale après avoir cliqué sur un lien
  };

  const handleCloseModal = (e) => {
    if (e.target === document.getElementById("modalCgv")) {
      handleLinkClick();
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
      return document.addEventListener('click', handleCloseModal)
    }
    document.body.classList.remove('modal-open');
    document.removeEventListener('click', handleCloseModal);
  }, [isModalOpen]);

  return (
    <>
      <span onClick={handleModalToggle}>Mentions légales & CGV</span>
      {isModalOpen && (
        <div className="modal-overlay" id="modalCgv">
          <div className="modal">
            <div className="close-modal">
              <button onClick={() => handleLinkClick()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" /> </svg>
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-title">
                <h2>{cgv.legalNotice.title}</h2>
                <div className="society-name">
                  <p>{cgv.legalNotice.editor}</p>
                  <img src="./assets/images/VJ_logo.png" alt="logo de l'entreprise" />
                </div>
              </div>
              <section className="legal-notice-content">
                <h3>{cgv.legalNotice.headOffice.title}</h3>
                <p><b>Nom :</b> {cgv.legalNotice.headOffice.agent}</p>
                <p><b>E-mail :</b> {cgv.legalNotice.headOffice.contact.mail}</p>
                <p><b>N° de téléphone :</b> {cgv.legalNotice.headOffice.contact.phone}</p>
                <p><b>Adresse :</b> {cgv.legalNotice.headOffice.place}</p>
              </section>
              <section className="general-condition">
                <h2>{cgv.termsAndConditions.title}</h2>
                <ul>
                  {cgv.termsAndConditions.article.map((article, i) => (
                    <li key={i}>
                      <h3>Article n°{article.id} :</h3>
                      <h4>{article.title}</h4>
                      {article.content.map((info, i) => (
                        <article className="article-content" key={i}>
                          <h5>{info.subtitle}</h5>
                          <p>{info.text}</p>
                        </article>
                      ))}
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalCgv;
