import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsentBanner = () => {
    const [analyticsIsActivate, setAnalyticsIsActivate] = useState(true);
    const [cgvIsActivate, setCgvIsActivate] = useState(true);
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        // Vérifie si le consentement a déjà été donné en vérifiant si le cookie existe
        const consentGiven = Cookies.get('cookieConsentGiven');
        if (consentGiven === 'true') {
            setShowBanner(false); // Masque la bannière si le consentement a déjà été donné
        }
    }, []);

    const handleAccept = () => {
        if (analyticsIsActivate) {
            Cookies.set('googleAnalyticsConsent', 'true', { expires: 365 });
        } else {
            Cookies.remove('googleAnalyticsConsent');
        }
        if (cgvIsActivate) {
            Cookies.set('cgvConsent', 'true', { expires: 365 });
        } else {
            Cookies.remove('cookieConsent');
        }
        Cookies.set('cookieConsentGiven', 'true', { expires: 365 });
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <div className="cookie-consent-banner">
                    <h5>Nous utilisons des cookies pour améliorer votre expérience.</h5>
                    <p>Acceptez-vous leur utilisation ?</p>
                    <div className='cookie-block'>
                        <p>Google Analytics</p>
                        <div onClick={() => setAnalyticsIsActivate(!analyticsIsActivate)} className={`toggle-background ${analyticsIsActivate ? "active" : ""}`}>
                            <div className='animate-translate'>
                                <button></button>
                            </div>
                        </div>
                    </div>
                    <div className='cookie-block'>
                        <p>Condition Générale de Vente (CGV)</p>
                        <div onClick={() => setCgvIsActivate(!cgvIsActivate)} className={`toggle-background ${cgvIsActivate ? "active" : ""}`}>
                            <div className='animate-translate'>
                                <button></button>
                            </div>
                        </div>
                    </div>
                    <button onClick={handleAccept}>Accepter</button>
                </div>
            )}
        </>
    );
};

export default CookieConsentBanner;
