import React, { useState, useEffect, useRef } from 'react';
// import GoogleMapComponent from './googleMap';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [operation, setOperation] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaValid, setCaptchaValid] = useState(false);
    const [isValid, setIsValid] = useState();
    const [isSubmitted, setIsSubmitted] = useState();
    const form = useRef();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleCaptchaChange = (e) => {
        setCaptchaValue(e.target.value);
    }

    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 10);
    };

    useEffect(() => {
        const generateRandomOperation = () => {
            const num1 = generateRandomNumber();
            const num2 = generateRandomNumber();
            let result = num1 + num2;

            if (result >= 10) {
                const resultString = result.toString();
                return `${num1} + ${num2} = ? .${resultString}`;
            }
            return `${num1} + ${num2} = ? .${result}`;
        };

        const newOperation = generateRandomOperation();
        setOperation(newOperation);
    }, []);

    useEffect(() => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        const areFieldsFilled = isValidEmail && name !== '' && message !== '';
        setShowCaptcha(areFieldsFilled);
    }, [email, name, message]);

    useEffect(() => {
        setCaptchaValid(captchaValue === operation.split('.')[1]);
    }, [captchaValue, operation]);

    const sendEmail = async (e) => {
        e.preventDefault();

        if (captchaValid) {
            emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            ).then((result) => {
                console.log(result.text);
                setIsValid(true);
                setIsSubmitted(true);
            }, (error) => {
                console.error(error.text);
            });
            form.current.reset();
            setEmail('');
            setName('');
            setMessage('');
            console.log('Formulaire envoyé !');
        } else {
            alert('Veuillez résoudre le captcha correctement !');
        }
    };

    return (
        <article id="contact">
            <section className="title">
                <h3>CONTACTEZ-NOUS</h3>
                <hr className="line-barre" />
            </section>
            <h5>UNE IDÉE ? UN PROJET ? N'HÉSITEZ PAS A <span>DEMANDER UN DEVIS</span> ! [GRATUIT]</h5>
            <div className="contact-content">
                <section className="contact-info">
                    <div>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="currentColor" d="M283 23.76c-80 .95-128.2 41.59-154.4 102.44c-26.2 60.7-32.7 141.1-35.67 226.9l-.33 9.4c10.5-.2 21.1-.5 31.6-.7c11.8 32.8 28.2 64 51.2 87c24.9 24.8 57.2 40.5 102.1 39.4c44.4-1.1 73.9-16.9 96.2-41.7c20.6-22.9 34.5-54.2 45.7-88.2c-5.7-2.2-11.6-4.2-17.3-6.1c-13.6 42.8-32.6 73.7-55.9 93.7c-24.8 21.3-54.5 29.4-82.5 25.7c-53-6.8-99.6-50.6-123.1-110.1l38.8-.9c21 42.8 56.4 71.2 88.8 75.4c18.9 2.5 36.9-2.1 54.6-17.3c16.5-14.2 32.7-38.6 44.7-76.4c-4.7-1.1-9.3-2.1-14.1-3.1c-13.5 26.3-26.3 45.1-39.6 57.9c-14 13.4-29.3 20-44.6 18.4c-15.3-1.5-29.1-10.3-42.5-23.7c-8.5-8.7-17-19.3-25.7-31.7c7.1 0 14.7-.3 21.5-.7c5.8 7.6 11.5 14.3 17 19.8c11.6 11.6 22.2 17.6 31.5 18.5c9.3 1 18.8-2.4 30.4-13.6c10.3-9.8 21.5-25.6 33.5-47.9c-3.8-.6-7.7-1.1-11.6-1.5c-10.3 15.3-22.4 32.9-37.5 41.2c-8.7 4.7-18.5 3.5-25.9-.3c-9.3-5.3-15.5-12.3-21.6-19.5c5.8-2 11.2-4.9 15.9-8.8c6 5.9 14.8 17.1 23 12.8c8.3-4.4 21.5-21 31.7-36.1c7.9-9.1 12.5-24 22.5-29.9c15.2-4.3 37.1 11.5 46.9-1.2c3.3-4.5 5.6-9 7.2-13.5l-15.8-.5l-8.8-18.3l27.7 1c-.2-11.7-2.1-20.4-4.1-31.9c8.7 1.6 18.5 2.6 26.2.7c-4.1-9.7-9.5-19.5-17.8-27.9c-41.2 5.3-77.4.5-99.4-32.4c32.5-13.7 71.2-17.8 91.2-3.2c.1-6.2-.1-12.4-1.2-18.5c7.1.3 12.9.8 20.2 1.3l.2-9.5c.7-24.7-6.6-53.7-25.7-76.97c-19.1-23.27-50.6-40-95.2-39.47m.3 17h3.7c38.3.41 62.4 14.35 77.8 33.08c13.6 16.54 20.2 37.36 21.5 56.06c-29.9-1.3-53 3-70.9 11.8c-19.7 9.7-32.6 24.9-40.5 41.9c-15.7 33.9-13.3 74.6-15.5 102.4c-1.8 23.5-6.8 37.1-13.4 44.7c-6.6 7.6-15.5 10.8-30 11.6l-105.4 2.3c3.2-81.9 10.5-157.4 34-211.9c24.5-56.65 64.1-91.05 138.7-91.94M351.7 174c-8.4.5-16 1.8-23.1 3.7c6 3.7 12.7 6.5 19.5 8.4c-1.2-4.4.7-9.6 3.6-12.1m17 3.9c1.6 3.6 1.2 7.6-.5 10.8c3.9-.1 7.8-.4 11.4-1.2c-2.6-4.2-7-7.3-10.9-9.6" /></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>PROFIL</h6>
                                    <div className="contact-portfolio-link">
                                        <a href="http://portfolio-julie-mancino.vercel.app/" target="_blank" rel="noopener noreferrer">
                                            Julie Mancino
                                        </a>
                                        <a href="http://portfolio-vincent-kbidi.vercel.app/" target="_blank" rel="noopener noreferrer">
                                            Vincent K/BIDI
                                        </a>
                                    </div>
                                </div>
                                <hr className="line-barre" />
                            </section>
                        </article>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5" /></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>ADRESSE</h6>
                                    <div className="contact-portfolio-link">
                                        <a href="https://www.google.com/maps/search/?api=1&query=75+avenue+marinville+94100+Saint-Maur-des-fossés" rel="noreferrer" target="_blank">
                                            <p>75 avenue marinville 94100</p>
                                            <p>Saint-Maur-des-fossés</p>
                                        </a>
                                    </div>
                                </div>
                                <hr className="line-barre" />
                            </section>
                        </article>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3m-2 20h-4v-1h4zm3.25-3H6.75V4h10.5z" /></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>TÉLÉPHONE</h6>
                                    <p>(+33) 7.50.03.94.72 (Grenoble)</p>
                                    <p>(+33) 7.55.64.32.25 (Paris)</p>
                                </div>
                                <hr className="line-barre" />
                            </section>
                        </article>
                    </div>
                    <div>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2" /></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>E-MAIL</h6>
                                    <p>contact@jv-design-et-tech.fr</p>
                                </div>
                                <hr className="line-barre" />
                            </section>
                        </article>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="currentColor"><path d="M17.029 8.016a1 1 0 1 0-2 0v6.277A1.988 1.988 0 0 0 16.03 18c.364 0 .706-.098.999-.269l3.669 2.119a1 1 0 0 0 1-1.732l-3.68-2.124a1.988 1.988 0 0 0-.99-1.704z" /><path d="M30 16c0-7.732-6.268-14-14-14S2 8.268 2 16s6.268 14 14 14s14-6.268 14-14m-3 0c0 6.075-4.925 11-11 11S5 22.075 5 16S9.925 5 16 5s11 4.925 11 11" /></g></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>HORAIRES</h6>
                                    <b>Lundi - Vendredi</b>
                                    <p>09H00 - 18h00</p>
                                </div>
                                <hr className="line-barre" />
                            </section>
                        </article>
                        <article>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M18.5 5.5h2v18h-17v-20c8.5-1 14.75-3 14.75-3h.25zm0 0H6m10.5 9A4.5 4.5 0 0 0 12 10m4.5 4.5A4.5 4.5 0 0 1 12 19m4.5-4.5c-.5.5-2 1-4.5 1s-4-.5-4.5-1M12 10a4.5 4.5 0 0 0-4.5 4.5M12 10c.966 0 1.75 2.015 1.75 4.5S12.966 19 12 19m0-9c-.966 0-1.75 2.015-1.75 4.5S11.034 19 12 19m-4.5-4.5A4.5 4.5 0 0 0 12 19" /></svg>
                            <section className="contact-info-text">
                                <div>
                                    <h6>S.I.R.E.T</h6>
                                    <div className="contact-portfolio-link">
                                        <a href="https://sirene.fr/sirene/public/recherche" target='_blank' rel="noopener noreferrer">
                                            90405911000026
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </article>
                    </div>
                </section>
                <div className='contact-map-form'>
                    <div className="contact-map">
                        <img src="./assets/images/google-map.png" alt="Ou je me trouve" />
                    </div>
                    {/* <GoogleMapComponent /> */}
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M12 4a8 8 0 0 0-6.96 11.947A4.99 4.99 0 0 1 9 14h6a4.99 4.99 0 0 1 3.96 1.947A8 8 0 0 0 12 4m7.943 14.076A9.959 9.959 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.958 9.958 0 0 0 2.057 6.076l-.005.018l.355.413A9.98 9.98 0 0 0 12 22a9.947 9.947 0 0 0 5.675-1.765a10.055 10.055 0 0 0 1.918-1.728l.355-.413zM12 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6" clipRule="evenodd" /></svg>
                            <input type="text" name="to_name" placeholder="Nom / Prénom" aria-label='Entrez votre nom et prénom' value={name} onChange={handleNameChange} required />
                            <p className='form-required'>*</p>
                        </div>
                        <div className="form-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8s8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47c.65.89 1.77 1.47 2.96 1.47c1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10m0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3" /></svg>
                            <input type="email" name="from_email" placeholder="E-mail" aria-label='Entrez votre adresse e-mail' value={email} onChange={handleEmailChange} required />
                            <p className='form-required'>*</p>
                        </div>
                        <div className="form-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3m-2 20h-4v-1h4zm3.25-3H6.75V4h10.5z" /></svg>
                            <input type="tel" name="from_tel" placeholder="Téléphone" aria-label='Entrez votre numéro de téléphone' />
                        </div>
                        <div className="form-block">
                            <textarea cols="30" rows="10" placeholder="Message..." aria-label='Décrivez nous votre demande' name="message" value={message} onChange={handleMessageChange} required />
                            <p className='form-required'>*</p>
                        </div>
                        {showCaptcha && (
                            <div className="form-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17 9V7c0-2.8-2.2-5-5-5S7 4.2 7 7v2c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3v-7c0-1.7-1.3-3-3-3M9 7c0-1.7 1.3-3 3-3s3 1.3 3 3v2H9zm4.1 8.5l-.1.1V17c0 .6-.4 1-1 1s-1-.4-1-1v-1.4c-.6-.6-.7-1.5-.1-2.1c.6-.6 1.5-.7 2.1-.1c.6.5.7 1.5.1 2.1" /></svg>
                                <input type="text" placeholder={`${operation.split('.')[0]}`} pattern={`^${operation.split('.')[1]}$`} onChange={handleCaptchaChange} required />
                                <p className='form-required'>*</p>
                            </div>
                        )}
                        {captchaValid && (
                            <div className={`submit ${isValid ? "valid" : ""}`}>
                                <button type="submit" disabled={isSubmitted}>
                                    <p>ENVOYER</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path d="M452.1 49L52.3 265.3c-6 3.3-5.6 12.1.6 14.9l68.2 25.7c4 1.5 7.2 4.5 9 8.4l53 109.1c1 4.8 9.9 6.1 10 1.2l-8.1-90.2c.5-6.7 3-13 7.3-18.2l207.3-203.1c1.2-1.2 2.9-1.6 4.5-1.3 3.4.8 4.8 4.9 2.6 7.6L228 338c-4 6-6 11-7 18l-10.7 77.9c.9 6.8 6.2 9.4 10.5 3.3l38.5-45.2c2.6-3.7 7.7-4.5 11.3-1.9l99.2 72.3c4.7 3.5 11.4.9 12.6-4.9L463.8 58c1.5-6.8-5.6-12.3-11.7-9z" fill="currentColor" /></svg>
                                </button>
                                <p className="submit-text">Votre message à bien était envoyer, nous vous recontacterons dans les plus bref délais !</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </article>
    );
};

export default Contact;