// const Separator = ({ imgSrc }) => {
//     const backgroundStyle = {
//         backgroundImage: `url(${imgSrc})`
//     };

//     return (
//         <article style={backgroundStyle} className="separator-content">
//             <div className="separator-block">
//                 <p>Compatible Mobile, Tablettes & PC</p>
//                 <h4>Développement Web Responsive</h4>
//                 <ul>
//                     <li>
//                         <h5>ANALYSE</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h8.925l-2 2H5v14h14v-6.95l2-2V19q0 .825-.587 1.413T19 21zm4-6v-4.25l9.175-9.175q.3-.3.675-.45t.75-.15q.4 0 .763.15t.662.45L22.425 3q.275.3.425.663T23 4.4q0 .375-.137.738t-.438.662L13.25 15zM21.025 4.4l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z" /></svg>
//                         </div>
//                     </li>
//                     <li>
//                         <h5>DESIGN</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="m22.017 3.874l-.59 1.581a250.264 250.264 0 0 1-.732 1.697c-.678 1.455-1.612 3.156-2.727 4.33c-1.066 1.12-2.674 2.153-3.962 2.886a4.996 4.996 0 0 1-1.36 4.557c-2.27 2.27-4.806 2.27-6.692 1.71c-1.43-.426-2.606-1.198-3.73-2.148a.394.394 0 0 1 .027-.624l.5-.362c.672-.507 1.325-1.126 1.49-1.955c.115-.925.18-1.43.197-1.513c.138-.689.43-1.471 1.138-2.18a4.996 4.996 0 0 1 4.556-1.36c.733-1.287 1.766-2.895 2.886-3.96c1.174-1.116 2.875-2.05 4.33-2.728l1.697-.733l1.581-.589a1.094 1.094 0 0 1 1.391 1.39ZM11.98 11.293a5.041 5.041 0 0 1 1.227 1.228c.392-.227.795-.473 1.19-.732l-.03-.067a3.312 3.312 0 0 0-.66-.93a3.354 3.354 0 0 0-.817-.603l-.179-.086c-.259.395-.505.798-.732 1.19Zm7.442-6.215c-.383.159-.8.34-1.23.54c-1.408.657-2.866 1.48-3.796 2.364a7.63 7.63 0 0 0-.47.493c.362.2.782.49 1.195.904c.414.413.704.833.904 1.195c.178-.156.344-.313.493-.47c.884-.93 1.707-2.388 2.364-3.797c.2-.43.381-.846.54-1.229" /></g></svg>
//                         </div>
//                     </li>
//                     <li>
//                         <h5>CODE</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M4.825 12.025L8.7 15.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.213-.325T2.426 12q0-.2.063-.375T2.7 11.3l4.6-4.6q.3-.3.713-.3t.712.3q.3.3.3.713t-.3.712zm14.35-.05L15.3 8.1q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375q0 .2-.062.375t-.213.325l-4.6 4.6q-.3.3-.7.288t-.7-.313q-.3-.3-.3-.712t.3-.713z" /></svg>
//                         </div>
//                     </li>
//                     <li>
//                         <h5>TESTS</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m20.315 4.319l-8.69 8.719l-3.31-3.322l-2.069 2.076l5.379 5.398l10.76-10.796zM5.849 14.689L0 19.682h24l-5.864-4.991h-3.2l-1.024.896h5.584l3.072 2.815h5.417l3.072-2.815h2.688l-.896-.896z" /></svg>
//                         </div>
//                     </li>
//                     <li>
//                         <h5>LIVRAISON</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M2 5.5V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2zM0 10h5v2H0zm0 5h8v2H0z" /></svg>
//                         </div>
//                     </li>
//                     <li>
//                         <h5>SUIVI</h5>
//                         <div className="round-parallax">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28c-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62" /><circle cx="9" cy="13" r="1" fill="currentColor" /><circle cx="15" cy="13" r="1" fill="currentColor" /><path fill="currentColor" d="M18 11.03A6.04 6.04 0 0 0 12.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 0 0 4.86-5.89c1.31 2.63 4 4.44 7.12 4.47" /></svg>
//                         </div>
//                     </li>
//                 </ul>
//             </div>
//         </article>
//     );
// };

// export default Separator;

import React from 'react';

const Separator = ({ imgSrc, data }) => {
    const backgroundStyle = {
        backgroundImage: `url(${imgSrc})`
    };

    return (
        <article style={backgroundStyle} className="separator-content">
            <div className="separator-block">
                <p>{data?.description}</p>
                <h4>{data?.title}</h4>
                <ul>
                    {data?.logo &&
                        data.logo.map((logo, idx) => (
                            <li key={idx}>
                                <h5>{logo.title}</h5>
                                <div className="round-parallax" dangerouslySetInnerHTML={{ __html: logo.src }} />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </article>
    );
};

export default Separator;