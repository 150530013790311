import React, { useState, useEffect } from "react";
import pricesData from "../fixtures/prices.json";
import { useSpring, animated } from "react-spring";

const Prices = () => {
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("selectedOptions")) || {}
  );
  const [prices, setPrices] = useState({});
  const [isVisible, setIsVisible] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".prices-card");
      const newIsVisible = {};
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const visible = rect.top < window.innerHeight && rect.bottom >= 0;
        newIsVisible[element.id] = visible;
      });
      setIsVisible(newIsVisible);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call the handler once to initialize the visibility state

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const springProps = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ transform: "translateX(0)", opacity: 1 });
    },
    config: { tension: 50, friction: 20 },
  });

  useEffect(() => {
    const updatedPrices = {};
    pricesData.largeCard.forEach((card) => {
      let totalPrice = card.price;
      if (selectedOptions[card.id]) {
        selectedOptions[card.id].forEach((option) => {
          totalPrice += option.value;
        });
      }
      updatedPrices[card.id] = totalPrice;
    });
    setPrices(updatedPrices);
    // Sauvegarde des options sélectionnées dans le localStorage
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOptionSelect = (e, cardId) => {
    const category = e.target.options[e.target.selectedIndex].text;
    const value = parseFloat(e.target.value);
    setSelectedOptions((prevState) => ({
      ...prevState,
      [cardId]: [
        ...(prevState[cardId] || []), // Existing options for the card
        { category, value },
      ],
    }));
  };

  const handleDeleteOption = (cardId, index) => {
    setSelectedOptions((prevState) => {
      const updatedOptions = { ...prevState };
      updatedOptions[cardId].splice(index, 1); // Remove the option at the specified index
      return updatedOptions;
    });
  };

  return (
    <section id="prices">
      <section className="title">
        <h3>NOS TARIFS</h3>
        <h4>Un tarif adapté à un site internet de qualité !</h4>
        <hr className="line-barre" />
      </section>
      <aside>
        <h5>
          UNE IDÉE ? UN PROJET ? N'HÉSITEZ PAS A <span>DEMANDER UN DEVIS</span>{" "}
          ! [GRATUIT]
        </h5>
      </aside>
      <section className="prices-content">
        {pricesData.largeCard.map((card, id) => (
          <animated.article
            key={id}
            id={`price-${id}`}
            className={`prices-card ${card.id}`}
            style={
              isVisible[`price-${id}`]
                ? springProps
                : { transform: "translateX(-100%)", opacity: 0 }
            }
          >
            <section className="price-card-title">
              <h6>{card.title}</h6>
              <hr className="line-barre" />
              <p>À PARTIR DE</p>
              <h5>{prices[card.id]}€</h5> {/* Utilisation du prix calculé */}
              <hr className="line-barre" />
              <b>Développé avec :</b>
              <ul className="price-tags">
                {card.tags.map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
              <p className="price-desc">{card.desc}</p>
            </section>
            <div className="price-block">
              <ul>
                {card.features.map((feature, i) => (
                  <li key={i}>
                    <p>{feature}</p>
                  </li>
                ))}
                {selectedOptions[card.id] &&
                  selectedOptions[card.id].map((option, i) => (
                    <li key={i}>
                      <span
                        className="delete-option"
                        onClick={() => handleDeleteOption(card.id, i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="CurrentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                          />
                        </svg>
                      </span>
                      <p>{option.category}</p>
                    </li>
                  ))}
              </ul>
              {card.options &&
                <select
                  value="" // Reset the select after an option is added
                  onChange={(e) => handleOptionSelect(e, card.id)}
                >
                  <option value="">+ Ajouter un élément</option>
                  {card.options.map((option, i) => {
                    // Filter out options that are already selected for this card
                    if (
                      !selectedOptions[card.id] ||
                      !selectedOptions[card.id].find(
                        (o) => o.category === option.category
                      )
                    ) {
                      return (
                        <option key={i} value={option.value}>
                          {option.category}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              }
            </div>
            {/* <button>Valider</button> */}
          </animated.article>
        ))}

        <div className="small-prices-cards">
          {pricesData.smallCard.map((card, i) => (
            <article className="small-card" key={i}>
              <section className="price-card-title">
                <h6>{card.title}</h6>
                <hr className="line-barre" />
                <p>{card.subtitle}</p>
                <h5 className="price-service">
                  {card.price}
                  <span>€/{card.periodicity}</span>
                </h5>
                {card.desc && <p className="small-card-desc">({card.desc})</p>}
                <button onClick={() => scrollToSection("contact")}>
                  ME CONTACTER
                </button>
              </section>
            </article>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Prices;
