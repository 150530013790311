import React, { useState, useEffect } from "react";
import ModalCgv from "./modalCgv";
import ModalPrivacyPolicy from "./modalPrivacyPolicy";
import { animated, useSpring } from "@react-spring/web";

const Layout = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [hasClicked, setHasClicked] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [navClass, setNavClass] = useState("");

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  // const [likeClick, setLikeClick] = useState(() => {
  //   const storedValue = localStorage.getItem("likeClick");
  //   return storedValue ? parseInt(storedValue, 10) : 559;
  // });

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLinkClick = (sectionId) => {
    scrollToSection(sectionId);
    document.body.classList.remove("modal-open");
    handleModalToggle(); // Fermer la modale après avoir cliqué sur un lien
  };

  // const moreClick = () => {
  //   if (!hasClicked) {
  //     setLikeClick((prevValue) => prevValue + 1);
  //     setHasClicked(true);
  //   }
  // };

  useEffect(() => {
    if (isModalOpen) {
      return document.body.classList.add("modal-open");
    }
    document.body.classList.remove("modal-open");
  }, [isModalOpen]);

  useEffect(() => {
    // localStorage.setItem("likeClick", likeClick.toString());

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 0) {
      setNavClass("scrolled");
    } else {
      setNavClass("");
    }
  }, [scrollY]);

  const AnimatedHeading = () => {
    const styles = useSpring({
      from: { opacity: 0, transform: "translateX(100%)" },
      to: { opacity: 1, transform: "translateX(0)" },
      config: { duration: 1000 },
    });

    return <animated.h2 style={styles}>CRÉATION DE SITES WEB</animated.h2>;
  };
 
  return (
    <>
      <header id="header">
        <div className="top">
          <div className="top-icones">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61554037989431"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visitez notre page Facebook"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/RUDQ8ju9zr"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visitez notre groupe Discord"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/jv_design_et_tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visitez notre compte instagram"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.fr/designettechcorporation/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visitez notre compte Pinterest"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M9.04 21.54c.96.29 1.93.46 2.96.46a10 10 0 0 0 10-10A10 10 0 0 0 12 2A10 10 0 0 0 2 12c0 4.25 2.67 7.9 6.44 9.34c-.09-.78-.18-2.07 0-2.96l1.15-4.94s-.29-.58-.29-1.5c0-1.38.86-2.41 1.84-2.41c.86 0 1.26.63 1.26 1.44c0 .86-.57 2.09-.86 3.27c-.17.98.52 1.84 1.52 1.84c1.78 0 3.16-1.9 3.16-4.58c0-2.4-1.72-4.04-4.19-4.04c-2.82 0-4.48 2.1-4.48 4.31c0 .86.28 1.73.74 2.3c.09.06.09.14.06.29l-.29 1.09c0 .17-.11.23-.28.11c-1.28-.56-2.02-2.38-2.02-3.85c0-3.16 2.24-6.03 6.56-6.03c3.44 0 6.12 2.47 6.12 5.75c0 3.44-2.13 6.2-5.18 6.2c-.97 0-1.92-.52-2.26-1.13l-.67 2.37c-.23.86-.86 2.01-1.29 2.7z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/services/page/680257328197256544/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visitez notre page LinkedIn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M5.004 7h-.029a2.235 2.235 0 1 1 .057-4.457A2.235 2.235 0 1 1 5.004 7m-1.986 3h4v12h-4zm14.5 0a4.473 4.473 0 0 0-3.5 1.703V10h-4v12h4v-5.5a2 2 0 0 1 4 0V22h4v-7.5a4.5 4.5 0 0 0-4.5-4.5"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            {/* <button onClick={moreClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M15 9.7h4a2 2 0 0 1 1.6.9a2 2 0 0 1 .3 1.8l-2.4 7.2c-.3.9-.5 1.4-1.9 1.4c-2 0-4.2-.7-6.1-1.3L9 19.3V9.5A32 32 0 0 0 13.2 4c.1-.4.5-.7.9-.9h1.2c.4.1.7.4 1 .7l.2 1.3zM4.2 10H7v8a2 2 0 1 1-4 0v-6.8c0-.7.5-1.2 1.2-1.2"
                  clipRule="evenodd"
                />
              </svg>
              Suivre
              <span>{likeClick}</span>
            </button> */}
          </div>
          <div className="top-contact">
            {/* <div className='contact-number'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863q-2.5-2.5-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3" /></svg>
                            <p>(+33) 7.50.03.94.72</p>
                        </div>
                        <div className='contact-mail'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M0 0h24v24H0z" /><path fill="currentColor" d="M22 7.535V17a3 3 0 0 1-2.824 2.995L19 20H5a3 3 0 0 1-2.995-2.824L2 17V7.535l9.445 6.297l.116.066a1 1 0 0 0 .878 0l.116-.066z" /><path fill="currentColor" d="M19 4c1.08 0 2.027.57 2.555 1.427L12 11.797l-9.555-6.37a2.999 2.999 0 0 1 2.354-1.42L5 4z" /></g></svg>
                            <p>designettechcorporation@gmail.com</p>
                        </div> */}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3m-2 20h-4v-1h4zm3.25-3H6.75V4h10.5z"
                />
              </svg>
              <a href="tel:+33750039472">(+33) 7.50.03.94.72</a>
              <p>ou</p>
              <a href="tel:+33755643225">(+33) 7.55.64.32.25</a>
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
                />
              </svg>
              <a
                href="mailto:contact@jv-design-et-tech.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@jv-design-et-tech.fr
              </a>
            </span>
          </div>
        </div>
        <div className={`nav-background ${navClass}`}>
          <nav>
            <h1>
              <img src="./assets/images/VJ_logo.png" alt="JV design logo" />
            </h1>
            <ul className="menu">
              <li
                className="menu-title"
                onClick={() => scrollToSection("services")}
              >
                SERVICES
              </li>
              {/* <li className="menu-title" onClick={() => scrollToSection('skills')}>COMPETENCES</li> */}
              <li
                className="menu-title"
                onClick={() => scrollToSection("projects")}
              >
                PROJET
              </li>
              {/* <li className="menu-title" onClick={() => scrollToSection('portfolio')}>PORTFOLIO</li> */}
              <li
                className="menu-title"
                onClick={() => scrollToSection("prices")}
              >
                TARIFS
              </li>
              {/* <li className="menu-title" onClick={() => scrollToSection('about')}>A PROPOS</li> */}
              <li
                className="menu-title"
                onClick={() => scrollToSection("contact")}
              >
                CONTACT
              </li>
              {/* <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024"><path fill="currentColor" d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8m-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8m0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8M115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8" /></svg>
                            </li> */}
              {/* <li
                className="menu-title"
                onClick={() => scrollToSection("header")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 9.3V4h-3v2.6L12 3L2 12h3v8h5v-6h4v6h5v-8h3zm-9 .7c0-1.1.9-2 2-2s2 .9 2 2z"
                  />
                </svg>
              </li> */}
            </ul>
            <div className="menu-mobile" onClick={handleLinkClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M4 18q-.425 0-.712-.288T3 17q0-.425.288-.712T4 16h16q.425 0 .713.288T21 17q0 .425-.288.713T20 18zm0-5q-.425 0-.712-.288T3 12q0-.425.288-.712T4 11h16q.425 0 .713.288T21 12q0 .425-.288.713T20 13zm0-5q-.425 0-.712-.288T3 7q0-.425.288-.712T4 6h16q.425 0 .713.288T21 7q0 .425-.288.713T20 8z"
                />
              </svg>
            </div>
          </nav>
        </div>
        <div className="presentation">
          <div className="presentation-title">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675t-.15-.75q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875z" /></svg> */}
            <section>
              {/* <h2>CRÉATION DE SITES WEB</h2> */}
              <AnimatedHeading/>
              <p>Création de site internet vitrine, corporate, évènementiel, e-commerce</p>
            </section>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76" /></svg> */}
          </div>
          <button
            className="scroll-button"
            aria-label="faites défiler jusqu'au service"
            onClick={() => scrollToSection("services")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m12 16.175l3.9-3.875q.275-.275.688-.288t.712.288q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062q-.2 0-.375-.062T11.3 18.3l-4.6-4.6q-.275-.275-.288-.687T6.7 12.3q.275-.275.7-.275t.7.275zm0-6L15.9 6.3q.275-.275.688-.287t.712.287q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062q-.2 0-.375-.062T11.3 12.3L6.7 7.7q-.275-.275-.288-.687T6.7 6.3q.275-.275.7-.275t.7.275z"
              />
            </svg>
          </button>
        </div>
        
        {isModalOpen && (
          <div className="modal">
            <div className="close-modal">
              <button onClick={() => handleLinkClick("header")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M20 20L4 4m16 0L4 20"
                  />
                </svg>
              </button>
            </div>
            <ul>
              <li onClick={() => handleLinkClick("services")}>SERVICES</li>
              {/* <li onClick={() => handleLinkClick('skills')}>COMPETENCES</li> */}
              <li onClick={() => handleLinkClick("projects")}>PROJET</li>
              {/* <li onClick={() => handleLinkClick('portfolio')}>PORTFOLIO</li> */}
              <li onClick={() => handleLinkClick("prices")}>TARIFS</li>
              {/* <li onClick={() => handleLinkClick('about')}>A PROPOS</li> */}
              <li onClick={() => handleLinkClick("contact")}>CONTACT</li>
              <li
                className="menu-title"
                onClick={() => handleLinkClick("header")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 9.3V4h-3v2.6L12 3L2 12h3v8h5v-6h4v6h5v-8h3zm-9 .7c0-1.1.9-2 2-2s2 .9 2 2z"
                  />
                </svg>
              </li>
            </ul>
          </div>
        )}
      </header>
      <main>{children}</main>
      <footer>
        <div className="link-footer">
          <img
            src="./assets/images/VJ_logo.png"
            alt="logo de JV design & tech"
          />
          <ul>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61554037989431"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez notre page Facebook"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/RUDQ8ju9zr"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez notre groupe Discord"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/jv_design_et_tech/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez notre compte instagram"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.pinterest.fr/designettechcorporation/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez notre compte Pinterest"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M9.04 21.54c.96.29 1.93.46 2.96.46a10 10 0 0 0 10-10A10 10 0 0 0 12 2A10 10 0 0 0 2 12c0 4.25 2.67 7.9 6.44 9.34c-.09-.78-.18-2.07 0-2.96l1.15-4.94s-.29-.58-.29-1.5c0-1.38.86-2.41 1.84-2.41c.86 0 1.26.63 1.26 1.44c0 .86-.57 2.09-.86 3.27c-.17.98.52 1.84 1.52 1.84c1.78 0 3.16-1.9 3.16-4.58c0-2.4-1.72-4.04-4.19-4.04c-2.82 0-4.48 2.1-4.48 4.31c0 .86.28 1.73.74 2.3c.09.06.09.14.06.29l-.29 1.09c0 .17-.11.23-.28.11c-1.28-.56-2.02-2.38-2.02-3.85c0-3.16 2.24-6.03 6.56-6.03c3.44 0 6.12 2.47 6.12 5.75c0 3.44-2.13 6.2-5.18 6.2c-.97 0-1.92-.52-2.26-1.13l-.67 2.37c-.23.86-.86 2.01-1.29 2.7z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/services/page/680257328197256544/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez notre page LinkedIn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M5.004 7h-.029a2.235 2.235 0 1 1 .057-4.457A2.235 2.235 0 1 1 5.004 7m-1.986 3h4v12h-4zm14.5 0a4.473 4.473 0 0 0-3.5 1.703V10h-4v12h4v-5.5a2 2 0 0 1 4 0V22h4v-7.5a4.5 4.5 0 0 0-4.5-4.5"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className="texte-footer-background">
          <section className="texte-footer">
            <article className="infos-footer">
              <h4>DÉVELOPPEUR INFORMATIQUE INDÉPENDANT</h4>
              <p>
                <b>
                  Développeur web front-end, back-end & Webdesigner freelance
                </b>
                , nous sommes à votre disposition pour répondre à tout type de
                projets de{" "}
                <b>
                  création de sites internet? création de site web, de développement spécifique ou
                  d'application web.
                </b>
              </p>
              <p>
                Passionné par les technologies liées au Web, nous mettons nos
                compétences au service de vos besoins dans divers domaines.
              </p>
            </article>
            <article className="contact-footer">
              <h4>JULIE MANCINO & VINCENT K/BIDI</h4>
              <span>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=75+avenue+marinville+94100+Saint-Maur-des-fossés"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  75, avenue marinville 94100 Saint-Maur-des-fossés
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"
                  />
                </svg>
              </span>
              <span>
                <div className="mail-phone-info">
                  <a href="tel:+33750039472">(+33) 7.50.03.94.72</a>
                  <a href="tel:+33755643225">(+33) 7.55.64.32.25</a>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3m-2 20h-4v-1h4zm3.25-3H6.75V4h10.5z"
                  />
                </svg>
              </span>
              <span>
                <div className="mail-phone-info">
                  <a href="mailto:contact@jv-design-et-tech.fr">
                    contact@jv-design-et-tech.fr
                  </a>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
                  />
                </svg>
              </span>
              <span>
                <a
                  href="http://portfolio-julie-mancino.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  portfolio de julie Mancino
                </a>
                <a
                  href="http://portfolio-vincent-kbidi.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  portfolio de vincent K/bidi
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11 17H7q-2.075 0-3.537-1.463T2 12q0-2.075 1.463-3.537T7 7h4v2H7q-1.25 0-2.125.875T4 12q0 1.25.875 2.125T7 15h4zm-3-4v-2h8v2zm5 4v-2h4q1.25 0 2.125-.875T20 12q0-1.25-.875-2.125T17 9h-4V7h4q2.075 0 3.538 1.463T22 12q0 2.075-1.463 3.538T17 17z"
                  />
                </svg>
              </span>
            </article>
          </section>
        </div>
        <div className="mention">
          <ModalCgv />
          <ModalPrivacyPolicy />
          <p className="copyright">JV Design & Tech - COPYRIGHT © 2024</p>
        </div>
      </footer>
    </>
  );
};

export default Layout;
