import React, { useEffect } from 'react';
import Modal from 'react-modal';

const ModalVideos = ({ showModal, closeModal, project }) => {

    useEffect(() => {
        Modal.setAppElement("#root");
    }, []);

    const modalClassName = project?.video === "bientôt" ? "modal-videos_maintenance" : "modal-videos_content";

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            contentLabel="Project Modal Videos"
            overlayClassName="modal-videos_overlay"
            className={modalClassName}
            shouldCloseOnOverlayClick={true}
        >
            {project?.video === "bientôt"
            ? <p>Bientôt disponible</p>
            : <iframe className="modal-video" src={project?.video} title="video des projets sur Youtube" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen />
            }
        </Modal>
    );
};

export default ModalVideos;