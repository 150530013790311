import React, { useState } from "react";
import projects from "../fixtures/projects.json";
import dataSeparator from "../fixtures/separator.json";
import Separator from "./separator";
import ModalVideos from "./modalVideos";
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModalVideo = (project) => {
    setShowModal(true);
    setSelectedProject(project);
  };

  const closeModalVideo = () => {
    setShowModal(false);
  };

  const AnimatedElement = ({ children }) => {
    const ref = React.useRef(null);
    const intersection = useIntersection(ref, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    const springProps = useSpring({
      opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
      transform:
        intersection && intersection.intersectionRatio >= 0.1
          ? "translateY(0)"
          : "translateY(40px)",
      config: { tension: 40, friction: 20 },
    });

    return (
      <animated.div ref={ref} style={springProps}>
        {children}
      </animated.div>
    );
  }

  return (
    <>
      <section id="projects">
        <section className="title">
        <AnimatedElement>
          <h3>NOS PROJETS</h3>
          <h4>Une partie des projets sur lesquels nous avons travaillé en création de site web.</h4>
          </AnimatedElement>
          <hr className="line-barre" />
        </section>
        <div className="project-content">
          {projects.map((project, i) => (
        <AnimatedElement>
            <section key={i}>
              {project.video ? (
                <div
                  className="project-img"
                  onClick={() => openModalVideo(project)}
                >
                  <div className="little-effect"></div>
                  <img src={project.src} alt={project.alt} />
                </div>
              ) : (
                <a href={project.link} target="_blank" rel="noreferrer">
                  <div className="project-img">
                    <div className="little-effect"></div>
                    <img src={project.src} alt={project.alt} />
                  </div>
                </a>
              )}
              <article className="project-text-block">
                <h4>{project.title}</h4>
                <p>{project.category}</p>

                <ul>
                  {project.tags.map((tag, i) => (
                    <li key={i}>
                      <a href={tag.link} target="_blank" rel="noreferrer">
                        {tag.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </article>

            </section>
          </AnimatedElement>
          ))}
        </div>
      </section>
      <ModalVideos
        showModal={showModal}
        closeModal={closeModalVideo}
        project={selectedProject}
      />

      <Separator
        imgSrc={"./assets/images/services_background.webp"}
        data={dataSeparator.project}
      />
    </>
  );
};

export default Projects;
