import Layout from "./components/layout";
import CookieConsentBanner from "./components/cookie";
import Services from "./components/services";
// import Skills from "./components/skills";
import Projects from "./components/projects";
import Prices from "./components/prices";
import Contact from "./components/contact";
// import Loading from "./components/loading";
// import React, { useState, useEffect } from 'react';

function App() {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000); // Temps du chargement, vous pouvez l'ajuster selon vos besoins

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
     {/* {isLoading ? (
        <Loading />
      ) : ( */}
      <Layout>
        <CookieConsentBanner />
        <Services />
        {/* <Skills /> */}
        <Projects />
        <Prices />
        <Contact />
      </Layout>
         {/* )} */}

    </>
  );
}

export default App;