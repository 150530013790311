import React, { useState, useEffect } from "react";
import privacyPolicy from "../fixtures/privacyPolicy.json";

const ModalPrivacyPolicy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLinkClick = () => {
    document.body.classList.remove('modal-open');
    handleModalToggle(); // Fermer la modale après avoir cliqué sur un lien
  };

  const handleCloseModal = (e) => {
    if (e.target === document.getElementById("modalPrivacy")) {
      handleLinkClick();
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
      return document.addEventListener('click', handleCloseModal)
    }
    document.body.classList.remove('modal-open');
    document.removeEventListener('click', handleCloseModal);
  }, [isModalOpen]);

  return (
    <>
      <span onClick={handleModalToggle}>Politique de confidentialités</span>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-overlay" id="modalPrivacy">
            <div className="modal">
              <div className="close-modal">
                <button onClick={() => handleLinkClick()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" /> </svg>
                </button>
              </div>
              <div className="modal-content">
                <h2>{privacyPolicy.title}</h2>
                <b className="confidential-intro">{privacyPolicy.intro}</b>
                <ul className="confidential-block">
                  {privacyPolicy.content.map((block, i) => (
                    <li key={i}>
                      <h3>{block.subtitle}</h3>
                      {block.intro && <p>{block.intro}</p>}
                      {block.content && <ul>
                        {block.content?.map((object, i) => (
                          <li key={i}>
                            <h4>{object.title}</h4>
                            {object.text?.map((text, i) => (
                              <p key={i}>{text}</p>
                            ))}
                          </li>
                        ))}
                      </ul>}
                      {block.description &&
                        <p>{block.description}</p>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalPrivacyPolicy;
